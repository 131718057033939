import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DeliveryService } from '../../services/deliveries.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { Router } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { OrderClient } from '../../models/orderClient';
import { ReportsExcel } from '../../models/reportsExcel';
import { CommonResponse } from 'src/app/models/http/response.model';
import { FormControl } from '@angular/forms';
import * as fs from 'file-saver'; // Importa la librería file-saver
import { ReportService } from '../../services/reports.service';
import { Workbook } from 'exceljs';


@Component({
  selector: 'app-deliveries-table',
  templateUrl: './deliveries-table.component.html',
  styleUrls: ['./deliveries-table.component.scss']
})
export class DeliveriesTableComponent {

  searchText: string = '';
  searchTextMainFilter: string = ''

  filters = [
    { label: 'Client Number', icoClass: 'bi bi-person', type: 'input', control: new FormControl('') },
    { label: 'Status', icoClass: 'bi bi-check', type: 'select', control: new FormControl(''), validValues: ['Completely processed', 'Partially processed'] },
    { label: 'Creation Date', icoClass: 'bi bi-calendar', type: 'date', control: new FormControl('') },
  ];

  readonly getDeliveriesObserver = {
    next: (data: CommonResponse<any>) => this.getDeliveriesNext(data),
    error: (error: CommonResponse<any>) => this.getDeliveriesError(error),
  };

  readonly gerDataReportsObserver = {
    next: (data: CommonResponse<any>) => this.getDataReportNext(data),
    error: (error: CommonResponse<any>) => this.getDataReportError(error),
  };



  registers: MatTableDataSource<OrderClient> = new MatTableDataSource<OrderClient>();
  registersReport: MatTableDataSource<ReportsExcel> = new MatTableDataSource<ReportsExcel>();
  displayedColumns: string[] = ['soldToCustomerNumer', 'soldName', 'docNumber', 'overallStat', 'creationDate', 'deliveryDetails'];

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private _deliveryService: DeliveryService,
    private _router: Router,
    private _pageLoadingService: PageLoadingService,
    private _snackbarService: SnackBarService,
    private _reportService: ReportService
  ) {
    this.registers.filterPredicate = (data: any, filter: string): boolean => {
      const filterValues = filter.split('|');
      const clientNumberFilter = filterValues[0] || '';
      const searchTextFilter = filterValues[1] || '';
      const statusFilter = filterValues[2] || '';
      const creationDateFilter = filterValues[3] || '';

      const matchClientNumber = !clientNumberFilter || data.soldToCustomerNumer.includes(clientNumberFilter);
      const matchSearchText = !searchTextFilter || data.soldName.toLowerCase().includes(searchTextFilter) || data.docNumber.toLowerCase().includes(searchTextFilter);
      const matchStatus = !statusFilter || data.overallStat === statusFilter;

      const recordDate = new Date(data.creationDate);
      const formattedRecordDate = recordDate.toLocaleDateString();

      const filterDate = new Date(creationDateFilter);
      if (isNaN(filterDate.getTime())) {
        return matchClientNumber && matchSearchText && matchStatus;
      }

      filterDate.setDate(filterDate.getDate() - 1);
      const formattedFilterDate = filterDate.toLocaleDateString();

      const matchCreationDate = !creationDateFilter || formattedRecordDate === formattedFilterDate;

      return matchClientNumber && matchSearchText && matchStatus && matchCreationDate;
    };


    this._reportService.getAllReportInformation().subscribe(this.gerDataReportsObserver);
    this._deliveryService.getListDeliveries().subscribe(this.getDeliveriesObserver);
    this._pageLoadingService.showLoadingGif();
  }

  ngOnInit() {

  }

  getDeliveriesNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if (data && data.body && data.body.data && data.body.data.orderInformation) {
      const orderInformation = data.body.data.orderInformation;
      const orders: OrderClient[] = orderInformation.map((order: any) => {
        return new OrderClient(
          order.docNumber,
          order.overallStat,
          order.creationDate,
          order.soldToCustomerNumer,
          order.soldName
        );
      });

      this.registers.data = orders;
      this.registers.paginator = this.paginator;

      this.registers.data.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
      this.registers._updateChangeSubscription();
    }
  }

  getDeliveriesError(error: CommonResponse<any>) {
    this._snackbarService.openSnackBar("An error occurred while obtaining the data");
    this._pageLoadingService.hideLoadingGif();
    console.log(error);
  }
  getDataReportNext(data: CommonResponse<any>) {
    this._pageLoadingService.hideLoadingGif();
    if (data && data.body) {
      const reportInformation = data.body;
      const dataReport: ReportsExcel[] = reportInformation.map((dataReport: any) => {
        return new ReportsExcel(

          dataReport.creationDate,
          dataReport.soldToCustomerNumber,
          dataReport.soldName,
          dataReport.docNumber,
          dataReport.deliveryNumber,
          dataReport.materialCode,
          dataReport.materialDescription,
          dataReport.materialShipped,
          dataReport.materialDelivered
        );
      });

      this.registersReport.data = dataReport;


      this.registersReport.data.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
      this.registersReport._updateChangeSubscription();
    }
  }

  getDataReportError(error: CommonResponse<any>) {
    this._snackbarService.openSnackBar("An error occurred while obtaining the data report");
    console.log(error);
  }

  showDetails(docNumber: number) {
    this._router.navigate(['/orders/info', docNumber]);
  }

  cleanFilter() {
    this.filters.forEach(filter => filter.control.setValue(''));
    this.applySearchFilter();
    this.getDeliveries();
  }

  applySearchMainFilter() {
    this.registers.filterPredicate = (data: OrderClient, filter: string): boolean => {
      const searchTextMainFilter = filter.trim().toLowerCase();
      const clientNumber = data.soldToCustomerNumer.toLowerCase();
      const soldName = data.soldName.toLowerCase();
      const docNumber = data.docNumber.toLowerCase();
      const overallStat = data.overallStat.toLowerCase();

      const recordDate = new Date(data.creationDate + 'T00:00:00');
      let desdeStr = `${recordDate.getDate().toString()}/${('0' + (recordDate.getMonth() + 1)).slice(-2).toString()}/${recordDate.getFullYear().toString()}`;
      const formattedRecordDate = desdeStr;

      // Verificar si el filtro incluye la fecha
      return clientNumber.includes(searchTextMainFilter) ||
        soldName.includes(searchTextMainFilter) ||
        docNumber.includes(searchTextMainFilter) ||
        overallStat.includes(searchTextMainFilter) ||
        formattedRecordDate.includes(searchTextMainFilter);
    };
    const searchValue = this.searchTextMainFilter.trim().toLowerCase();

    if (!searchValue) {
      this.registers.filter = '';
      return;
    }

    this.registers.filter = searchValue;
  }

  applySearchFilter() {
    const searchValue = this.searchText.trim().toLowerCase();
    const clientNumberValue = this.filters[0].control.value || '';
    const statusValue = this.filters[1].control.value || '';
    const creationDateValue = this.filters[2].control.value || '';

    console.log('creationDateValue', creationDateValue);

    this.registers.filter = `${clientNumberValue}|${searchValue}|${statusValue}|${creationDateValue}`;
  }

  getDeliveries() {
    this._deliveryService.getListDeliveries(/* pasa aquí los filtros */).subscribe(this.getDeliveriesObserver);
  }


  async exportExcel(): Promise<void> {
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Deliveries');

    // 2. Definir los encabezados de las columnas en la segunda fila
    worksheet.columns = [
      { header: 'Fecha Creación Orden', key: 'creationDate', width: 20 },
      { header: 'Código de la Institución', key: 'soldToCustomerNumber', width: 20 },
      { header: 'Institución', key: 'soldName', width: 30 },
      { header: '# de Orden SA', key: 'docNumber', width: 20 },
      { header: '# de entrega', key: 'deliveryNumber', width: 15 },
      { header: 'Material Código', key: 'materialCode', width: 20 },
      { header: 'Descripción material', key: 'materialDescription', width: 30 },
      { header: 'Cantidades Ordenadas', key: 'materialShipped', width: 20 },
      { header: 'Cantidades Entregadas', key: 'materialDelivered', width: 20 },
      { header: '# de guía', key: 'guia', width: 15 },
      { header: 'Fecha de entrega', key: 'fechaEntrega', width: 20 },
    ];


    worksheet.getRow(1).eachCell(x => x.destroy);
    // 3. Agregar los encabezados de columnas a la segunda fila
    const headerRow = worksheet.addRow(worksheet.columns.map(col => col.header));

    // 4. Aplicar estilos a la fila de encabezados
    headerRow.eachCell((cell) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '1F4E78' } // Fondo azul
      };
      cell.font = {
        bold: true,
        color: { argb: 'FFFFFFFF' }
      };
      cell.alignment = {
        horizontal: 'center',
        vertical: 'middle'
      };
      cell.border = {
        top: { style: 'thin', color: { argb: 'FF000000' } },
        left: { style: 'thin', color: { argb: 'FF000000' } },
        bottom: { style: 'thin', color: { argb: 'FF000000' } },
        right: { style: 'thin', color: { argb: 'FF000000' } },
      };
    });

    // 5. Agregar los datos
    this.registersReport.data.forEach((item) => {
      worksheet.addRow({
        creationDate: item.creationDate,
        soldToCustomerNumber: item.soldToCustomerNumber,
        soldName: item.soldName,
        docNumber: item.docNumber,
        deliveryNumber: item.deliveryNumber,
        materialCode: item.materialCode,
        materialDescription: item.materialDescription,
        materialShipped: item.materialShipped,
        materialDelivered: item.materialDelivered
      });
    });

    // 6. Aplicar bordes y alternancia de color para las filas de datos
    worksheet.eachRow((row, rowNumber) => {
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };

        // Alternancia de color de fondo para las filas de datos
        if (rowNumber > 2) { // Saltar el título y la fila de encabezados
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: rowNumber % 2 === 0 ? 'FFFFFFFF' : 'FFDCE6F1' } // Blanco y gris claro
          };
        }
      });
    });


    const row = worksheet.getRow(1);
    row.eachCell((cell) => {
      cell.value = null; // Eliminar el contenido de cada celda
    });

    worksheet.mergeCells("A1:K1");
    const now = new Date();
    const formattedDate = now.toLocaleDateString('en-GB'); // dd/mm/yyyy

    worksheet.getCell('A1').value = {
      richText: [
        { text: 'DELIVERIES REPORTS ' }, // Parte 1: Texto
        { text: '- ' },                   // Parte 2: Separador
        { text: formattedDate + ' ' },    // Parte 3: Fecha
      ]
    };

    worksheet.getCell('A1').fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: '1F4E78' }
    };
    worksheet.getCell('A1').font = {
      bold: true,
      size:20,
      color: { argb: 'FFFFFFFF' }      
    };
    worksheet.getCell('A1').alignment = {
      wrapText: true, 
      horizontal: 'center', 
      vertical: 'middle'      
    };
    worksheet.getRow(1).height = 40; // Ajustar el alto de la fila del título

    // 7. Guardar el archivo
    const buffer = await workbook.xlsx.writeBuffer();
    fs.saveAs(new Blob([buffer]), 'Reporte_Entregas.xlsx');
  }
}
