import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker'; 
import { MatNativeDateModule } from '@angular/material/core'; 
import { MatInputModule } from '@angular/material/input'; 
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import {MatPaginatorModule} from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';

import { DeliveriesTableComponent } from './orders/components/deliveries-table/deliveries-table.component';
import { OrdersMainViewComponent } from './orders/components/orders-main-view/orders-main-view.component';
import { NavbarComponent } from './shared/componentes/navbar/navbar.component';
import { FooterComponent } from './shared/componentes/footer/footer.component';
import { HeaderComponent } from './shared/componentes/header/header.component';
import { BasicLayoutComponent } from './layouts/basic-layout/basic-layout.component';
import { EditDeliveryComponent } from './orders/components/edit-delivery/edit-delivery.component';
import { DataExportComponent } from './orders/components/data-export/data-export.component';

import {WebcamModule} from 'ngx-webcam';
import { DeliveryStatusComponent } from './orders/components/delivery-status/delivery-status.component';

@NgModule({
  declarations: [
    AppComponent,
    DeliveriesTableComponent,
    OrdersMainViewComponent,
    NavbarComponent,
    FooterComponent,
    HeaderComponent,
    BasicLayoutComponent,
    EditDeliveryComponent,
    DataExportComponent,
    DeliveryStatusComponent

  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    AuthModule,
    HttpClientModule,
    MatSnackBarModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatFormFieldModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatInputModule,
    MatTableModule,
    MatToolbarModule,
    MatCardModule,
    MatDialogModule,
    MatPaginatorModule,
    MatExpansionModule,
    WebcamModule,
    NgxMatIntlTelInputComponent,
    MatSnackBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
