<div class="container-fluid mb-5">
  <div class="mb-5">
    <h2>Orders</h2>
    <hr />
  </div>
  <div class="row">
    <div class="col-md-3">
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <app-deliveries-table (DataExportEvent)="getDataExport($event)" (isReadyEvent)="getIsReadyData($event)"></app-deliveries-table>
    </div>
  </div>
</div>