export class ReportsExcel {
    creationDate: Date;
    soldToCustomerNumber: string;
    soldName: string;
    docNumber: string;
    deliveryNumber: string;
    materialCode: string;
    materialDescription: string;
    materialShipped: number;
    materialDelivered: number;

    constructor(creationDate: Date,
        soldToCustomerNumber: string,
        soldName: string,
        docNumber: string,
        deliveryNumber: string,
        materialCode: string,
        materialDescription: string,
        materialShipped: number,
        materialDelivered: number) {
        this.creationDate = creationDate;
        this.soldToCustomerNumber = soldToCustomerNumber;
        this.soldName = soldName;
        this.docNumber = docNumber;
        this.deliveryNumber = deliveryNumber;
        this.materialCode = materialCode;
        this.materialDescription = materialDescription;
        this.materialShipped = materialShipped;
        this.materialDelivered = materialDelivered
    }
}
