export class OrderClient {
    docNumber: string;
    overallStat: string;
    creationDate: string;
    soldToCustomerNumer: string;
    soldName: string;

    constructor(DocNumber: string,
        OverallStat: string,
        CreationDate: string,
        SoldToCustomerNumer: string,
        SoldName: string) {
        this.docNumber = DocNumber;
        this.overallStat = OverallStat;
        this.creationDate = CreationDate;
        this.soldToCustomerNumer = SoldToCustomerNumer;
        this.soldName = SoldName;
    }
}
