import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { CookieTokenService } from 'src/app/auth/services/cookie-token.service';
import { environment as env } from 'src/environments/environment';
import { catchError, map } from 'rxjs';
@Injectable({

    providedIn: 'root'

})



export class ReportService {
    constructor(
        private http: HttpClient,
        private _cookieTokenService: CookieTokenService
    ) { }

    getAllReportInformation() {
        const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);
        return this.http.get(`${env.url_api}/Reports/GetAllReportInformation`, { observe: 'response' as 'response', headers: headers })
            .pipe(
                catchError((error: any) => {
                    throw error.status;
                }),
                map((response: any) => {
                    return response;
                })
            );
    }
}