<div class="header">
    <h5>Delivery status</h5>
    <mat-icon id="close" (click)="closeModal()">close</mat-icon>
</div>

<div class="main-container">

    <div *ngIf="deliveryStatus.length === 0 && !error" class="no-data-message">
        No delivery status found
      </div>
      <div *ngIf="error" class="error-message">
        {{ error }}
      </div>

    <div class="order-container" *ngFor="let status of deliveryStatus">
        <mat-accordion *ngIf="deliveryStatus.length > 0">
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        Tracking ID: {{ status.trackingDelivery }}
                    </mat-panel-title>
                </mat-expansion-panel-header>

                <table mat-table [dataSource]="status.events" class="mat-elevation-z8">
                    <ng-container matColumnDef="date">
                        <th mat-header-cell *matHeaderCellDef>Date</th>
                        <td mat-cell *matCellDef="let event">{{ convertDate(event.date) | date: 'MM/dd/yyyy' }}</td>
                    </ng-container>

                    <ng-container matColumnDef="time">
                        <th mat-header-cell *matHeaderCellDef>Time</th>
                        <td mat-cell *matCellDef="let event">{{ convertTime(event.time) }}</td>
                    </ng-container>

                    <ng-container matColumnDef="type">
                        <th mat-header-cell *matHeaderCellDef>Status Type</th>
                        <td mat-cell *matCellDef="let event">{{ event.status.type }}</td>
                    </ng-container>

                    <ng-container matColumnDef="description">
                        <th mat-header-cell *matHeaderCellDef>Description</th>
                        <td mat-cell *matCellDef="let event">{{ event.status.description }}</td>
                    </ng-container>

                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef>Code</th>
                        <td mat-cell *matCellDef="let event">{{ event.status.code }}</td>
                    </ng-container>

                    <ng-container matColumnDef="statusCode">
                        <th mat-header-cell *matHeaderCellDef>Status Code</th>
                        <td mat-cell *matCellDef="let event">{{ event.status.statusCode }}</td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </mat-expansion-panel>
        </mat-accordion>
        <mat-icon (click)="downloadProofOfDelivery(status.trackingDelivery)">download</mat-icon>
    </div>

    <div *ngIf="error">{{ error }}</div>
</div>