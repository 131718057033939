import { Component, ViewChild } from '@angular/core';
import { DataExportComponent } from '../data-export/data-export.component';
import { DeliveriesTableComponent } from '../deliveries-table/deliveries-table.component';

@Component({
  selector: 'app-orders-main-view',
  templateUrl: './orders-main-view.component.html',
  styleUrls: ['./orders-main-view.component.scss']
})
export class OrdersMainViewComponent {
  @ViewChild(DataExportComponent) dataExport: DataExportComponent | undefined;
  @ViewChild(DeliveriesTableComponent) deliveriesTable!: DeliveriesTableComponent;

  getDataExport(event: any) {    
    this.dataExport!.dataExportArray = event;
  }

  getIsReadyData(event: any){
    this.dataExport!.isReady = event;    
  }


}
