import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as env } from 'src/environments/environment';
import { LoggedUser } from 'src/app/users/models/user.model';
import { catchError } from 'rxjs/operators';
import { CookieTokenService } from './cookie-token.service';
import { SnackBarService } from 'src/app/shared/services/snackbar.service';
import { Router } from '@angular/router';
import { ResponseHelper } from 'src/app/shared/helpers/response.helper';
import { map } from 'rxjs/operators';
import { UserAuthLogin } from '../models/auth.model';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  
  private readonly USER_SESSION_KEY = "logged_user";

  /*
    Session timeout is a timeout configured for expires the user session.
    At this.login, this timeout is created with a subscription to logout when
    the timeout completes.
    When the user session expires cookie and session data are deleted and the
    user is rederected to login view.
  */
  private sessionTimeout?: NodeJS.Timeout;
  private readonly sessionTimeoutLogoutObserver = {
    next: (data: any) => {
      this._router.navigate(['/auth']);
      this._snackbarService.openSnackBar("Se ha vencido la sesión.");
    },
    error: (error: any) => { }
  }

  constructor(private _httpClient: HttpClient,
    private _cookieTokenService: CookieTokenService,
    private _snackbarService: SnackBarService,
    private _router: Router) {
  }

  /**
   * If there is a logged user returns it, else return null.
   * @return {LoggedUser | null} logged user
   */
  getLoggedUser(): LoggedUser | null {
    const user = sessionStorage.getItem(this.USER_SESSION_KEY);

    if (user) {
      return JSON.parse(user);
    }

    return null;
  }

  /**
   * Update the session storage user information.
   * @param {LoggedUser} user : Logged user information
   */
  updateSessionUser(user: LoggedUser) {
    sessionStorage.removeItem(this.USER_SESSION_KEY);
    sessionStorage.setItem(this.USER_SESSION_KEY, JSON.stringify(user));
  }

  /**
  * Validates if current session has a logged user.
  * @return {boolean} there is a logged user
  */
  isLogged(): boolean {
    return this._cookieTokenService.isValidToken() &&
      sessionStorage.getItem(this.USER_SESSION_KEY) !== null &&
      sessionStorage.getItem(this.USER_SESSION_KEY) !== undefined;
  }


  // login(login: Login): Observable<any> {
  //   return this._httpClient.post<Response>(this.myAppUrl + this.myApiUrl, login, { observe: 'response' });
  // }

  /**
   * Make a login request to API and returns the request result as an observable.
   * @param {string} userEmail : user email
   * @param {string} password : user Password
   * @return {Observable} login request observable
   */

  login(userEmail: string, password: string) {

    const request: UserAuthLogin = {
      "userEmail": userEmail,
      "password": password
    };

    return this._httpClient.post(`${env.url_api}/Users/login`, request, { observe: 'response' as 'response' })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {

          const responseBody = response.body;
          this.sessionTimeout = setTimeout(() => { this.logout().subscribe(this.sessionTimeoutLogoutObserver); }, env.session_duration_minutes * 60000);

          if (ResponseHelper.responseDontHaveErrors(responseBody)) {
            this.updateSessionUser(responseBody.data.userInformation);
            this._cookieTokenService.setCookieToken(responseBody.data.authToken.tokenValue, responseBody.data.authToken.expiration);
          }

          return ResponseHelper.generateCommonResponseFromHttpResponse(response);
        })
      );
  }

  /**
  * Make a logout request to API, delete the auth cookie and session storage user data and returns the operations result as an observable.
  * @return {Observable} logout operations observable
  */
  logout() {

    const headers = new HttpHeaders().set('Authorization', `${env.token_type} ${this._cookieTokenService.getCookieToken()}`);    
    return this._httpClient.get(`${env.url_api}/Users/logout`, { observe: 'response' as 'response', headers: headers })
      .pipe(
        catchError(error => {
          throw ResponseHelper.generateCommonResponseFromHttpErrorResponse(error);
        }),
        map((response: HttpResponse<any>) => {

          if (response.status == 204) {
            clearTimeout(this.sessionTimeout);
            this._cookieTokenService.deleteCookieToken();
            sessionStorage.removeItem(this.USER_SESSION_KEY);
          }

          return response.status;
        })
      );
  }
}
