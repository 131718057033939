<div class="row staticHeaderMenu">
  <div class="d-flex align-items-center">
    <!-- <div *ngFor="let menuItem of userMenu"> -->
    <div>
      <nav>
        <a class="btn-nav" routerLinkActive="router-link-active" [routerLink]="['/deliveries']">Home | </a>
        <a class="btn-nav" routerLinkActive="router-link-active" [routerLink]="['/deliveries']">Orders</a>
      </nav>
    </div>
  </div>
</div>