<!-- <ion-title>{{ folder }}</ion-title> -->
<!-- <header>
  <div class="toolbar">
    <img src="../../../../assets/logo.png" alt="Descripción de la imagen" />
  </div>
</header> -->
<div class="mainComponent">
  <div class="content">
    <div class="title">
      <div>
        <h1>We welcome you</h1>
      </div>
      <div>
        <p>Enter your login information</p>
      </div>
    </div>
    <form [formGroup]="loginData" novalidate (ngSubmit)="login()">
      <div class="form">
        <div class="input">
          <i class="bi bi-person-fill iconLoginCustom"></i>
          <input
            formControlName="userEmail"
            type="text"
            name="userEmail"
            placeholder="User"
          />
        </div>
        <div class="input">
          <i class="bi bi-lock-fill iconLoginCustom"></i>
          <input
            formControlName="password"
            type="password"
            name="password"
            placeholder="Password"
          />
        </div>
      </div>
      <!-- Ejemplo de un icono de Angular Material -->
      <div class="form">
        <button class="btn-accept" type="submit">Login</button>
      </div>
    </form>
  </div>
</div>
