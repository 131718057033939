import { DIALOG_DATA } from '@angular/cdk/dialog';
import { Component, Inject, OnInit } from '@angular/core';
import { CommonResponse } from 'src/app/models/http/response.model';
import { DeliveryService } from '../../services/deliveries.service';
import { PageLoadingService } from 'src/app/shared/services/page-loading.service';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-delivery-status',
  templateUrl: './delivery-status.component.html',
  styleUrls: ['./delivery-status.component.scss']
})
export class DeliveryStatusComponent implements OnInit {
  deliveryStatus: any[] = [];
  error: string | null = null;


  displayedColumns: string[] = ['date', 'time', 'type', 'description', 'code', 'statusCode'];

  readonly getOrderStatusObserver = {
    next: (data: CommonResponse<any>) => this.getStatusNext(data),
    error: (error: CommonResponse<any>) => this.getStatusError(error),
  };

  constructor(
    @Inject(DIALOG_DATA) public data: any,
    private _deliveryService: DeliveryService,
    private _pageloadService: PageLoadingService,
    private _dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  getStatusNext(data: CommonResponse<any>) {
    console.log(data);
    this.deliveryStatus = data.body;
    this._pageloadService.hideLoadingGif();
  }

  getStatusError(error: CommonResponse<any>) {
    console.log(error);
    this.error = 'Error al cargar los datos';
    this._pageloadService.hideLoadingGif();
  }

  convertDate(dateString: string): Date {
    const year = +dateString.substring(0, 4);
    const month = +dateString.substring(4, 6) - 1;
    const day = +dateString.substring(6, 8);

    return new Date(year, month, day);
  }

  convertTime(timeString: string): string {
    const hours = timeString.substring(0, 2);
    const minutes = timeString.substring(2, 4);
    const seconds = timeString.substring(4, 6);

    return `${hours}:${minutes}:${seconds}`;
  }

  ngOnInit() {
    this._deliveryService.getDeliveryStatus(this.data.delivery).subscribe(this.getOrderStatusObserver);
    this._pageloadService.showLoadingGif()
  }

  closeModal() {
    this._dialog.closeAll()
  }

  downloadProofOfDelivery(trackingId: number) {
    this._pageloadService.showLoadingGif()
    this._deliveryService.downloadDeliveryStatus(trackingId).subscribe(
      (response: any) => {
        const blob = new Blob([response.body], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `ProofOfDelivery_${trackingId}.pdf`;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        window.URL.revokeObjectURL(url);
        this._pageloadService.hideLoadingGif()
      },
      error => {
        if (error === 400) {
          this.showErrorMessage('Error 400: No se pudo descargar la prueba de entrega.');
          this._pageloadService.hideLoadingGif()
        } else {
          this.showErrorMessage('Error al descargar el archivo.');
          this._pageloadService.hideLoadingGif()
        }
      }
    );
  }

  showErrorMessage(message: string) {
    this._snackBar.open(message, '', {
      duration: 5000,
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      panelClass: ['custom-snackbar']
    });
  }
  
}
